import React, { useState, useEffect } from "react"
import "../components/layout.css"
import Iframe from "react-iframe"
import Seo from "../components/seo"
import axios from "axios"
import * as queryString from "query-string"

const TecPage = ({ location }) => {
  const name = "Soporte tecnico"
  const feedback = "No"
  const isBrowser = typeof window !== "undefined"
  let { sid, s, bv } = queryString.parse(location.search)

  let lsSession =
    isBrowser && localStorage.getItem("session")
      ? localStorage.getItem("session")
      : null

  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState("no sesion")

  const headers = {
    Authorization: "bearer 40b3ff5fdeaf4ca6851eecadd6eec23c",
  }

  useEffect(() => {
    if (sid) {
      setSession(sid)
      setLoading(false)
    } else if (lsSession) {
      setSession(lsSession)
      setLoading(false)
    } else {
      getBotId()
    }

    eventSubscribe()
  }, [])

  const getBotId = async () => {
    try {
      const getBotId = await axios({
        method: "get", //you can set what request you want to be
        url:
          "https://api.33bot.io/v1/conversation/chat/618514683a84680008317ab6/bots",
        headers,
      })
      //const bot_id = getBotId.data[0].id

      const newSession = await axios({
        method: "post", //you can set what request you want to be
        url: "https://api.33bot.io/v1/conversation/create",
        data: {
          bot_id: getBotId.data[0].id,
        },
        headers,
      })

      const someSession = newSession.data.id
      setSession(someSession)
      localStorage.setItem("session", someSession)

      let globalVars =  {
        candidatura_seleccionada: {
          text: name,
          value: name,
        },
        feedback: {
          text: feedback,
          value: feedback,
        },
        bot_url: {
          text: `${location.origin}${location.pathname}?sid=${someSession}`,
          value: `${location.origin}${location.pathname}?sid=${someSession}`,
        },
        fuente_recrutamiento: {
          text: s,
          value: s,
        },
      };

      if (bv) {
        let bot_variables = atob(bv);
        bot_variables = JSON.parse(bot_variables);

        Object.keys(bot_variables).map(key => {
          globalVars[key] = {text: bot_variables[key], value: bot_variables[key]};
        });
      }

      const updateSession = await axios({
        method: "post", //you can set what request you want to be
        url: "https://api.33bot.io/v1/conversation/update",
        data: {
          session_id: someSession,
          global_vars: globalVars,
        },
        headers,
      })

      if (updateSession.data.status === "ok") {
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
    }
    console.log(`iniciando chatbot con sesión ${session}`)
  }

  const eventSubscribe = () => {
    window.addEventListener(
      "message",
      function (event) {
        const data = event.data
        if (event.data.event) {
          switch (data.event) {
            case "clearStorage":
              localStorage.removeItem("session")
              break
            default:
              console.log(data)
          }
        }
      },
      false
    )
  }

  return (
    <>
      <Seo title="Trabaja en Marktel" />
      <div style={{ width: "100vw", height: "100vh" }}>
        {loading ? (
          <div>Cargando...</div>
        ) : (
          <Iframe
            url={`https://chat.33bot.io/61856124e8b96200090f3e12?r=web&close=0&session=${session}`}
            width="100%"
            height="100%"
            allow="camera;microphone"
            frameborder="0"
          />
        )}
      </div>
    </>
  )
}

export default TecPage
